import React from 'react';
import CarForm from './components/CarForm';

function App() {
  return (
    <div className="App">
      <CarForm />
    </div>
  );
}

export default App;
